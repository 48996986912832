/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import classes from './styles.module.css';

function ModalReview({
  show, setShow, reviewLink, complaint,
}) {
  return (
    <div className={`${classes.modal} ${show ? classes.show : ''}`} onClick={() => setShow(false)}>
      <div className={classes.modalContent} onClick={(e) => e.stopPropagation()}>
        <button className={classes.closeButton} onClick={() => setShow(false)}>
          ⨉
        </button>
        <div className={classes.modalHeader}>
          <h2>How was your experience in our restaurant?</h2>
        </div>
        <div className={classes.modalBody}>
          <p>
            We would love to know about your experience at our restaurant. Please leave us your
            review or any complaints about our service, attention, or product quality.
          </p>
        </div>
        <div className={classes.modalFooter}>
          {complaint && (
            <button
              className={classes.noButton}
              onClick={() => {
                window.open(complaint, '_blank');
                setShow(false);
              }}
            >
              🗣️ I didn't like it
            </button>
          )}
          <button
            className={classes.yesButton}
            onClick={() => {
              window.open(reviewLink, '_blank');
              setShow(false);
            }}
          >
            I liked it 🥂
          </button>
        </div>
      </div>
    </div>
  );
}

export default ModalReview;
