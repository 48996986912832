/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import { message } from 'antd';
// Prime react
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { useParams } from 'react-router-dom';

// Bootstrap
import { Col, Container, Row } from 'react-bootstrap';

// Components
import Greetings from '../Greetings';
import Skeleton from '../Skeleton';
import Footer from '../Footer';
import Slider from '../Slider';
import ModalReview from '../modals';

// imports
import WineSvg from '../../assets/icons/wine.svg';

// styles
import classes from './styles.module.css';

// Icons
import LanguageIcon from '@mui/icons-material/Language';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';
import AdUnitsIcon from '@mui/icons-material/AdUnits';

// api
import { getLinksRestaurant, getRestaurant } from '../../utils/getData';

function ChooseMenu({
  restaurant, menus, handleSelectMenu, visible,
}) {
  const [data, setDate] = useState();
  const params = useParams();
  const [showModal, setShowModal] = useState(false);
  const [reviewLink, setReviewLink] = useState('');
  const [complaint, setComplaint] = useState('');

  useEffect(() => {
    const fetchLinksData = async () => {
      try {
        const responseRestaurant = await getRestaurant(params.id);
        const id = responseRestaurant?.[0]?.id;
        const response = await getLinksRestaurant(id);
        if (response) {
          const [daraUser] = response;
          setDate(daraUser);
        }
      } catch (error) {
        console.log('Error: ', error);
      }
    };
    fetchLinksData();
  }, [params.id]);

  const goLink = (link) => {
    const phoneNumberRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
    const isPhoneNumber = phoneNumberRegex.test(link);

    if (isPhoneNumber) {
      const tempInput = document.createElement('input');
      tempInput.value = link;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand('copy');
      document.body.removeChild(tempInput);
      message.success('Phone number copied to clipboard');
    } else {
      window.open(link, '_blank');
    }
  };

  const renderIcon = (link) => {
    const lowercaseLink = link.toLowerCase();

    if (lowercaseLink.includes('instagram')) {
      return <InstagramIcon />;
    }
    if (lowercaseLink.includes('facebook')) {
      return <FacebookIcon />;
    }
    if (lowercaseLink.includes('whatsapp')) {
      return <WhatsAppIcon />;
    }
    if (lowercaseLink.includes('telegram')) {
      return <TelegramIcon />;
    }
    if (lowercaseLink.includes('phoneNumber')) {
      return <AdUnitsIcon />;
    }
    if (lowercaseLink.includes('review')) {
      return <LanguageIcon />;
    }

    return <LanguageIcon />;
  };

  const handleButtonClick = (link, title, complaintLink = '') => {
    if (title.toLowerCase().includes('review')) {
      setReviewLink(link);
      setShowModal(true);
      if (complaintLink) {
        setComplaint(complaintLink);
      }
    } else {
      goLink(link);
    }
  };

  return (
    <Sidebar
      position="top"
      visible={visible}
      closeOnEscape={false}
      showCloseIcon={false}
      maskClassName={classes.sidebar}
      style={{ backgroundColor: restaurant?.shortUrl === 'la-biblioteque' ? '#1E2E5F' : 'black' }}
    >
      <Container>
        <ModalReview
          show={showModal}
          setShow={setShowModal}
          reviewLink={reviewLink}
          complaint={complaint}
        />
        <Row className="justify-content-center m-0">
          <Col className="p-0" sm={9} md={8} lg={6} xs={12}>
            {!menus ? (
              <Skeleton width="100%" height="3rem" />
            ) : (
              <div className={classes.content}>
                {/* TODO: descomentar cuando se tengan las fotos de sliders */}
                {false && <Slider restaurant={restaurant} />}
                <div className="mt-5 px-5">
                  <Greetings restaurant={restaurant} />
                  <br />
                  <div>
                    <p
                      style={{
                        fontSize: 'large',
                        color: 'white',
                      }}
                    >
                      {' '}
                      Restaurant Menu
                      {' '}
                    </p>
                    {menus.map((menu) => (
                      <Button
                        key={menu.id}
                        className={classes.menu}
                        onClick={() => handleSelectMenu(menu)}
                      >
                        <img src={WineSvg} alt={menu.name} />
                        {menu.name}
                      </Button>
                    ))}
                  </div>
                  {(data?.links.length > 0 || data?.alterLinks.length > 0) && (
                    <div>
                      {data?.links.map((linkObject) => Object.entries(linkObject).map(
                        ([key, link]) => key !== 'complaint'
                            && link && (
                              <Button
                                key={key}
                                className={classes.menu}
                                onClick={() => {
                                  if (key.toLowerCase() === 'review') {
                                    const complaintLink = data?.links.find((obj) => obj.complaint)?.complaint || '';
                                    handleButtonClick(link, key, complaintLink);
                                  } else {
                                    handleButtonClick(link, key);
                                  }
                                }}
                              >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  {renderIcon(key)}
                                  <span style={{ marginLeft: '2rem' }}>
                                    {key.charAt(0).toUpperCase() + key.slice(1)}
                                  </span>
                                </div>
                              </Button>
                        ),
                      ))}
                      {data?.alterLinks?.map((item) => (
                        <Button
                          key={item?.link}
                          className={classes.menu}
                          onClick={() => handleButtonClick(item?.link, item?.title)}
                        >
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            {renderIcon(item.title.toLowerCase())}
                            <span style={{ marginLeft: '2rem' }}>{item.title}</span>
                          </div>
                        </Button>
                      ))}
                    </div>
                  )}
                </div>
                {(params.id === 'no-manches'
                  || params.id === 'laveinte'
                  || params.id === 'la-diosa'
                  || params.id === 'la-diosa-8') && (
                  <div
                    style={{
                      width: '80%',
                      alignSelf: 'center',
                      color: '#FFF',
                      marginTop: '36px',
                    }}
                  >
                    <p style={{ textAlign: 'center' }}>WARNING</p>
                    <p>
                      Consuming raw or undercooked meats, poultry, seafood, shellfish, or eggs may
                      increase your risk of foodborne illness, especially if you have certain
                      medical conditions.
                    </p>
                    <p>
                      also please note that an automatic
                      {' '}
                      {params.id === 'laveinte' ? '20%' : '18%'}
                      {' '}
                      service charge will be added to all bills
                    </p>
                  </div>
                )}

                <div className={classes.containerFooter}>
                  <Footer logo={restaurant.logo} data={data} />
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </Sidebar>
  );
}
export default ChooseMenu;
