import React from 'react';

// react-bootstrap
import Carousel from 'react-bootstrap/Carousel';

// imports

import cocktailsImg from '../../assets/images/cocktails.jpeg';
import beetImg from '../../assets/images/beer.jpg';

// styles
import styles from './styles.module.css';

function Slider({ restaurant }) {
  return (
    <div className={styles.containerSlider}>
      <Carousel
        controls={false}
        className={
          restaurant?.shortUrl === 'la-biblioteque' ? styles.carouselBiblioteque : styles.carousel
        }
      >
        <Carousel.Item className={styles.item}>
          <img className={styles.imgCarrousel} src={cocktailsImg} alt="Cocktails" />
        </Carousel.Item>
        <Carousel.Item className={styles.item}>
          <img className={styles.imgCarrousel} src={beetImg} alt="Cocktails" />
        </Carousel.Item>
      </Carousel>
    </div>
  );
}
export default Slider;
